exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-page-2-jsx": () => import("./../../../src/pages/page-2.jsx" /* webpackChunkName: "component---src-pages-page-2-jsx" */),
  "component---src-templates-features-builder-jsx": () => import("./../../../src/templates/featuresBuilder.jsx" /* webpackChunkName: "component---src-templates-features-builder-jsx" */),
  "component---src-templates-features-page-builder-jsx": () => import("./../../../src/templates/featuresPageBuilder.jsx" /* webpackChunkName: "component---src-templates-features-page-builder-jsx" */),
  "component---src-templates-page-builder-jsx": () => import("./../../../src/templates/pageBuilder.jsx" /* webpackChunkName: "component---src-templates-page-builder-jsx" */),
  "component---src-templates-place-builder-jsx": () => import("./../../../src/templates/placeBuilder.jsx" /* webpackChunkName: "component---src-templates-place-builder-jsx" */),
  "component---src-templates-post-builder-jsx": () => import("./../../../src/templates/postBuilder.jsx" /* webpackChunkName: "component---src-templates-post-builder-jsx" */),
  "component---src-templates-post-page-builder-jsx": () => import("./../../../src/templates/postPageBuilder.jsx" /* webpackChunkName: "component---src-templates-post-page-builder-jsx" */)
}

